import { useEffect, useState } from "react";
// import ReCAPTCHA from "react-google-recaptcha";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
const Captcha = ({ onChange }) => {

  
  const { executeRecaptcha } = useGoogleReCaptcha();
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }
    executeRecaptcha('form_submission_token').then((token) => {
      onChange(token);
    })
   }
  }, [executeRecaptcha])

  return (
    <>
    </>
  );
};

export default Captcha;
