'use client'
// import { Button } from '@material-tailwind/react'
import { Button } from 'reactstrap'
import { Delete } from '@mui/icons-material'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'


export default function DButton({ title = "title", customClass = "", onclick =null, type = "contained", size, redirect = null, background = "bg-[#1b5df3]" }) {
  const [DataRendered, setDataRendered] = useState(false)
  useEffect(()=>{
    setDataRendered(true)
  },[])

  return (
    <>{(DataRendered) && 
      
        (redirect) ?
        <Link href={redirect}>
          <Button size={size} className={"lg:mx-4 mx-2 lg:ml-0 ml-0 text-black border lg:w-fit w-full  rounded px-4 py-1 bg-[#1b5df3] text-white btn-primary px-10 capitalize font-normal text-base py-2 w-fit rounded border-0" + " " + customClass}>
            {title ? title : ''}
          </Button>
        </Link>
        :
        (onclick)?
        <div>
          <Button size={size} onClick={onclick} className={"lg:mx-4 mx-2 lg:ml-0 ml-0 text-black border w-fit rounded px-4 py-1 bg-[#1b5df3] text-white btn-primary px-10 capitalize font-normal text-base py-2 w-fit rounded border-0 " + " " + customClass}>
            {title ? title : ''}
          </Button>
        </div>
        :

        <div>
          <Button size={size} className={"capitalize text-sm font-semibold bg-[#1b5df3] lg:mx-4 mx-2 lg:ml-0 ml-0 rounded" + " " + customClass}>
            {title ? title : ''}
          </Button>
        </div>
      

    }
    </>
  )
}
