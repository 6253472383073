const env= process.env.ENV
import constants from './constants';
import { AES, enc } from 'crypto-js';

var str=""
var enc_key=constants.ENC_KEY;

// const encode=(value="")=>{
//     str= AES.encrypt(JSON.stringify(value),enc_key).toString()
//     if(env==='local'){
//         return value
//     }else{
//         return str;
//     }
// }

// const decode=(value="")=>{
//     str= AES.decrypt(value,enc_key).toString()
//     if(env==='local'){
//         return value
//     }else{
//         return JSON.parse(str)
//     }
// }

const encode = (value = "") => {
    if (env === 'local') {
        return value;
    } else {
        const encryptedStr = AES.encrypt(JSON.stringify(value), enc_key).toString();
        return encryptedStr;
    }
}

const decode = (value = "") => {
    if (env === 'local') {
        return value;
    } else {
        const decryptedStr = AES.decrypt(value, enc_key).toString(enc.Utf8);
        return JSON.parse(decryptedStr);
    }
}

export { encode, decode }
