import React from 'react';

const Loading = ({status=true}) => {
  return (
   (status) ? 
    <div className="loader-container fixed top-0 left-0 w-full h-full bg-[rgba(255, 255, 255, 0.7)] flex justify-center item-center z-[9999]">
      <div className="loader border-[4px solid #f3f3f3] border-top[4px solid #3498db] rounded-md w-[50px] h-[50px] animation-[spin 2s linear infinite] "></div>
    </div>
    : 
    <></>
  
  );
};

export default Loading;