import React, {useEffect, useState} from 'react';
import dynamic from 'next/dynamic'
import DButton from '../components/elements/DButton';
// import HeroBanner from '../components/Common/HeroBanner';
const Navbar = dynamic(() => import('../components/Layouts/Navbar', { ssr: false }))
const HeroBanner = dynamic(() => import('../components/Common/HeroBanner', { ssr: false }))
const WhyPharmdelve = dynamic(() => import('../components/PharmDelve/WhyPharmdelve', { ssr: false }))
const IndicationAnalysis = dynamic(() => import('../components/PharmDelve/IndicationAnalysis', { ssr: false }))
const GrowBusiness = dynamic(() => import('../components/PharmDelve/GrowBusiness', { ssr: false }))
const UniqueAnalysis = dynamic(() => import('../components/PharmDelve/UniqueAnalysis', { ssr: false }))
const TabSection = dynamic(() => import('../components/PharmDelve/TabSection', { ssr: false }))
const PayAsUse = dynamic(() => import('../components/PharmDelve/PayAsUse', { ssr: false }))
const AdvantagesSection = dynamic(() => import('../components/PharmDelve/AdvantagesSection', { ssr: false }))
const Usp = dynamic(() => import('../components/PharmDelve/Usp', { ssr: false }))
const Breadcrumb = dynamic(() => import('../components/Layouts/Breadcrumb', { ssr: false }))
import CommonPopup from '../components/PopupForm/CommonPopup';
import * as Yup from 'yup';
const Footer = dynamic(() => import('../components/Layouts/Footer', { ssr: false }));
import axios from 'axios'


const Pharmdelve = () => {
  const [DataRendered, setDataRendered] = useState(false);
  useEffect(()=>{
    setDataRendered(true)
  },[])
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const options = {
    btn_name: "Submit"
  }

  const phoneRegExp = /^(\+\d{1,4})?(\d{9,14})$/

  const fields = [
    {
      name: 'name',
      label: '',
      placeholder: 'Full Name*',
      validation: Yup.string().matches(/^[a-zA-Z\s]*$/, 'Name can only contain letters and spaces').required('Name is required'),
      customClass: 'md:col-span-6 col-span-12'
    },
    {
      name: 'email',
      type: 'email',
      label: '',
      placeholder: 'Add Your Company E-mail Address*',
      validation: Yup.string().email('Invalid email').required('Email is required'),
      customClass: 'md:col-span-6 col-span-12'
    },
    {
      name: 'company_name',
      label: '',
      placeholder: 'Company Name',
      customClass: 'md:col-span-6 col-span-12'
    },
    {
      name: 'phone',
      label: '',
      placeholder: 'Phone Number',
      validation: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
      customClass: 'md:col-span-6 col-span-12'
    }
  ];
  
  return (
    <>
        <Navbar />
        <HeroBanner
            background={process.env.IMG_CDN+"img/pharmdelve/png/banner.png"}
            title={<>Indication based Actionable Insights and Data</>}
            description={<>A healthcare research subscription platform having the exhaustive coverage of domain for guiding your journey towards growth
            </>}
            buttonOnBanner={<>
              <div className="flex flex-col md:flex-row sm:grid-cols-4 grid-cols-1">
                <DButton title="Start a free Trial" customClass='mb-3 md:mb-0' onclick={openModal}/>
                  <CommonPopup isOpen={isModalOpen} closeModal={closeModal} 
                    fields={fields}
                    options={options}
                    page_name="Subscription"
                    form_name="grow-your-business-form"
                    formTitle="Get 7 Days Free Trial"
                    endpoint="/enquiry/pharmdelve-trial"
                  />
                <DButton title="Register/Login" customClass="w-8/12" redirect="https://pharmdelve.delveinsight.com/login" />
              </div>
            </>}
        />
        <Breadcrumb />
        <WhyPharmdelve title={'Analyzing the future in the present'}/>
        <IndicationAnalysis title={'A Repository of Indication Analysis'}/>
        <GrowBusiness />
        <TabSection title={"DelveInsight's Subscription Platform"} shortDescription={"PharmDelve is a technology-driven repository of global business intelligence and market research. It is packed with advanced features that aim to transform how market reports are delivered and navigated."}/>
        <UniqueAnalysis />
        <PayAsUse title="Flexible Packages" shortDescription="We provide handcrafted report packages that are completely customized for our clients. Whether a therapeutic- or report-type-based package, it can be used by multiple users, making it a versatile platform. The packages are tailored to each individual. Subscriptions can be customized to you and your specific needs, whether you are a pharmaceutical company or an individual."/>
        <div style={{backgroundColor:'#f8f9fa'}} >
            <AdvantagesSection title="Reasons to Collaborate With Us" />
        </div>
        <Usp title="Advantages for Clients"/>
        <Footer />
    </>
  )
}

Pharmdelve.getInitialProps = async (NextPageContext) => {
  const slugData = NextPageContext.pathname
  try {
      const response = await axios.get(process.env.API_URL + '/meta' + slugData);
      return {
          title: (response?.data.meta_title) ? response?.data.meta_title : "Healthcare Market Research Reports & Consulting Firm | DelveInsight",
          description: (response?.data.meta_description) ? response?.data.meta_description : "DelveInsight is a pioneer in Healthcare Market Research Reports & Consulting Services. We advise Pharma leaders on making strategic decisions across all therapy areas & geographies.",
          keywords: (response?.data.meta_keywords) ? response?.data.meta_keywords : "pharmaceutical business consulting, market research company, pharma consulting, biotech consulting, pharma market research, pharma market insights, biotech consulting,  pharma consulting service, pharma leading, market trends.",
      }
  } catch (error) {
      console.error('Error fetching data:', error.message);
      return {
          title: "Healthcare Market Research Reports & Consulting Firm | DelveInsight",
          description: "DelveInsight is a pioneer in Healthcare Market Research Reports & Consulting Services. We advise Pharma leaders on making strategic decisions across all therapy areas & geographies.",
          keywords: "pharmaceutical business consulting, market research company, pharma consulting, biotech consulting, pharma market research, pharma market insights, biotech consulting,  pharma consulting service, pharma leading, market trends.",
      };
  }
}

export default Pharmdelve;
